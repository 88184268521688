<template>
  <div class="overflow-hidden">
    <v-layout wrap>
      <v-flex xs12 sm8>
        <div>
          <div class="headline">
            Find influencers
          </div>
          <v-text-field
            v-model="search"
            prepend-inner-icon="search"
            @keyup="searchMethod"
            placeholder="Search word"
            clearable
            class="custom-search hidden-xs-only"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex xs12 sm4 class="text-right hidden-xs-only">
        <div class="favorite-influencer-wrapper">
          <v-icon
            v-if="filters.favorite"
            color="error"
            @click="toggleFavourite(false)"
            >fas fa-heart</v-icon
          >
          <v-icon color="error" v-else @click="toggleFavourite(true)"
            >fal fa-heart</v-icon
          >
          <span class="greytxt--text">
            Only favourites
          </span>
        </div>
      </v-flex>

      <v-row no-gutters>
        <v-col cols="12" md="9" class="hidden-xs-only">
          <div class="d-flex mr-auto ">
            <v-select
              placeholder="Gender"
              :items="genderItems"
              return-object
              :menu-props="{
                contentClass: 'custom-selection',
                bottom: true,
                offsetY: true
              }"
              v-model="filters.gender"
              @blur="saveFilters"
              class="mb-2 custom-select width"
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 0">
                  Gender
                </span>
              </template>
            </v-select>
            <v-select
              placeholder="Categories"
              :items="formattedCategories"
              v-model="filters.category_ids"
              :return-object="false"
              multiple
              @blur="saveFilters"
              :menu-props="{
                contentClass: 'custom-selection',
                bottom: true,
                offsetY: true
              }"
              class="mb-2 custom-select"
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 0">
                  Categories
                </span>
              </template>
            </v-select>
            <v-select
              placeholder="Age"
              :items="ageItems"
              v-model="filters.age_ranges"
              :return-object="false"
              multiple
              @blur="saveFilters"
              :menu-props="{
                contentClass: 'custom-selection',
                bottom: true,
                offsetY: true
              }"
              class="mb-2 custom-select width"
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 0">
                  Age
                </span>
              </template>
            </v-select>
            <v-select
              v-model="mySelectedCountries"
              placeholder="Countries"
              :items="countries"
              item-text="name"
              item-value="iso_3166_2"
              :loading="!countries.length"
              multiple
              @change="updateSelectedCountries"
              :menu-props="{
                contentClass: 'custom-selection',
                bottom: true,
                offsetY: true
              }"
              class="mb-2 custom-select"
            ></v-select>
            <v-menu
              v-model="followersRange"
              :close-on-content-click="false"
              :nudge-width="400"
              offset-x
              content-class="range-slider"
              @input="setRange"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="advanced-filter mr-10"
                >
                  <span> Follower count </span>
                  <v-icon>fal fa-angle-down</v-icon>
                </div>
              </template>

              <v-card>
                <v-range-slider
                  :value="[0, 5]"
                  min="0"
                  max="5"
                  ticks="always"
                  height="100"
                  v-model="range"
                  tick-size="5"
                  :tick-labels="ticksRange"
                  class="align-center"
                >
                  <template v-slot:prepend>
                    Min
                  </template>
                  <template v-slot:append>
                    Max
                  </template>
                </v-range-slider>
              </v-card>
            </v-menu>
            <div class="advanced-filter" @click="advancedDialog = true">
              <span>
                Advanced search
              </span>
              <v-icon small>fal fa-cog</v-icon>
            </div>
            <div
              class="advanced-filter remove-filter ml-5"
              @click="cleanFilters(false)"
              v-if="isFilterChips"
            >
              <span>
                Remove filters
              </span>
              <v-icon small>fal fa-trash-alt</v-icon>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="2" offset-md="1" class="text-right">
          <div class="ml-auto sortering">
            <v-combobox
              v-model="selectedSorting"
              :items="sortingItems"
              label="Sortering"
              content-class="mb-2"
            >
              <template v-slot:item="{ item }">
                <v-list-item :disabled="item.permission && ! hasPermission(item.permission)" @click="selectSorting(item);">
                  <v-list-item-title>
                    <subscription-crown-icon
                      v-if="item.permission && ! hasPermission(item.permission)"
                      class="mr-2"
                    ></subscription-crown-icon>

                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          <span class="total-wrap" v-if="influencers.length">
            {{ limit }} of {{ totalInfluencers }}
          </span>
        </v-col>
      </v-row>
    </v-layout>
    <filtered-chips
      v-if="isFilterChips && $vuetify.breakpoint.smAndUp"
      :filters="cloneDeep(filters)"
      :categories="formattedCategories"
      :ages="ageItems"
      @remove="removeChip($event)"
    ></filtered-chips>

    <v-container
      v-if="influencers.length > 0"
      fluid
      grid-list-xl
      class="pl-0 pr-0"
    >
      <v-layout wrap class="influencers-list" ref="influencersWrapper">
        <influencer-card
          :influencer="item"
          class="influncer-card"
          :ref="item.uuid"
          v-for="item of influencers"
          :key="item.uuid"
        >
          <template v-slot:footer>
            <v-btn
              v-on="on"
              small
              outlined
              block
              color="primary"
              @click="showStatsModal(item)"
            >
              See media data
            </v-btn>   
            <v-btn
              v-on="on"
              small
              outlined
              block
              color="primary"
              @click="
                selectedInfluencer = item;
                showCampaignInvitationDialog = true;
              "
            >
              Invite to campaign
            </v-btn>     
          </template>
        </influencer-card>
        <v-btn
          v-if="influencers.length > 12 && isUserScrolling"
          class="hidden-xs-only"
          color="accent"
          fixed
          bottom
          right
          fab
          @click="scrollToTop()"
        >
          <v-icon color="white">fal fa-arrow-to-top</v-icon>
        </v-btn>
      </v-layout>
    </v-container>
    <div v-else>
      <v-container fluid grid-list-xl class="pl-0 pr-0">
        <v-layout
          wrap
          class="influencers-list"
          ref="influencersWrapper"
          v-if="skeletonArray.length > 0"
        >
          <v-flex
            xs12
            sm6
            md4
            lg4
            xl4
            v-for="(skeleton, i) of skeletonArray"
            :key="i"
          >
            <v-card class="influencer-skeleton">
              <div class="d-flex justify-center">
                <v-skeleton-loader
                  class="rounded"
                  height="150px"
                  width="150px"
                  type="image"
                ></v-skeleton-loader>
              </div>
              <div>
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </div>
              <div class="pb-0">
                <v-skeleton-loader
                  type="list-item-avatar-two-line"
                ></v-skeleton-loader>
              </div>
              <div class="influencer-skeleton-buttons mb-2">
                <v-skeleton-loader type="button"></v-skeleton-loader>
                <v-skeleton-loader type="button"></v-skeleton-loader>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap class="influencers-list" ref="influencersWrapper" v-else>
          <v-flex xs12>
            <span class="subtitle-1 primary--text">
              Whoops! No result for your search.
            </span>
            <div class="empty-search-img">
              <img src="./../../assets/searchFailed.svg" />
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <!--Mobile Filter -->

    <v-btn
      class="hidden-sm-and-up first-btn mobile-filter-btn"
      fab
      fixed
      bottom
      right
      small
      @click="showMobileSearch = true"
    >
      <v-icon>fal fa-search</v-icon>
    </v-btn>
    <v-btn
      class="hidden-sm-and-up mobile-filter-btn"
      fab
      fixed
      bottom
      right
      small
      @click="showMobileFilters = true"
    >
      <v-icon>fas fa-filter</v-icon>
    </v-btn>
    <v-pagination
      v-if="totalPages && totalPages > 1 && influencers.length"
      v-model="page"
      class="my-2"
      :length="totalPages"
      :total-visible="7"
      @input="handlePageChange"
    >
    </v-pagination>
    <advanced-filter-dialog
      v-model="advancedDialog"
      :filters="filters"
      @saved="saveAdvancedFilters($event)"
    ></advanced-filter-dialog>
    <influencer-dialog
      v-if="selectedInfluencer"
      v-model="showInfluencerDialog"
      :influencer="selectedInfluencer"
      @input="!$event ? (selectedInfluencer = null) : null"
      @change="influencerChange"
    ></influencer-dialog>
    <bulk-invite-campaign-dialog
      v-model="showCampaignInvitationDialog"
      :selectedInfluencer="selectedInfluencer"
    >
    </bulk-invite-campaign-dialog>
    <filter-influencers-mobile
      v-model="showMobileFilters"
      :filters="filters"
      @saved="filters = $event"
      @saveFilters="saveFilters"
      :genderItems="genderItems"
      :ageItems="ageItems"
      :ticksRange="ticksRange"
      :categories="formattedCategories"
      :range="range"
      :ticksNumberRange="ticksNumberRange"
    >
    </filter-influencers-mobile>
    <v-dialog v-model="showMobileSearch" max-width="350px">
      <v-card>
        <v-card-title>Search word</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="search"
            prepend-inner-icon="search"
            placeholder="Search word"
            clearable
            class="custom-search"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showMobileSearch = false">
            Undo
          </v-btn>
          <v-btn color="primary" @click="saveSearch()">
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import InfluencerCard from "../cards/InfluencerCard";
import AdvancedFilterDialog from "../dialogs/AdvancedFilterDialog";
import FilterInfluencersMobile from "@/components/common/filters/FilterInfluencersMobile";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import FilteredChips from "@/components/common/filters/FilteredChips";
import InfluencerDialog from "../dialogs/InfluencerDialog";
import BulkInviteCampaignDialog from "@/components/common/dialogs/BulkInviteCampaignDialog";
import { mapActions, mapState, mapGetters } from "vuex";
import _ from "lodash";
import SubscriptionCrownIcon from "../business/icon/SubscriptionCrownIcon";

export default {
  mixins: [search_filters_url],
  components: {
    SubscriptionCrownIcon,
    BulkInviteCampaignDialog,
    FilterInfluencersMobile,
    FilteredChips,
    InfluencerDialog,
    InfluencerCard,
    AdvancedFilterDialog
  },
  data: () => ({
    countries: [],
    mySelectedCountries: [],
    skeletonArray: [],
    followersRange: false,
    limit: 18,
    totalInfluencers: null,
    selectedSorting: {
      text: "Recommended",
      orderBy: "recommended",
      asc: true
    },
    search: "",
    showMobileFilters: false,
    showMobileActions: false,
    influencers: [],
    filters: {},
    loading: false,
    selectedInfluencer: null,
    showInfluencerDialog: false,
    showMobileSearch: false,
    advancedDialog: false,
    showCampaignInvitationDialog: false,
    totalPages: null,
    page: 1,
    range: [0, 5],
    cardWidth: {
      xs: 100,
      sm: 50,
      md: 33.33,
      lg: 33.33,
      xl: 33.33
    },
    ageItems: [
      {
        text: "Under 18",
        min: 0,
        max: 18,
        value: "18"
      },
      {
        text: "18-24",
        min: 18,
        max: 24,
        value: "18-24"
      },
      {
        text: "25-29",
        min: 25,
        max: 29,
        value: "25-29"
      },
      {
        text: "30-34",
        min: 30,
        max: 34,
        value: "30-34"
      },
      {
        text: "35-39",
        min: 35,
        max: 39,
        value: "35-39"
      },
      {
        text: "40-44",
        min: 40,
        max: 44,
        value: "40-44"
      },
      {
        text: "45-50",
        min: 45,
        max: 50,
        value: "45-50"
      },
      {
        text: "Over 50",
        min: 50,
        max: 150,
        value: "50"
      }
    ],
    min: 0,
    max: 100000,
    ticksNumberRange: [0, 5000, 10000, 25000, 50000, 100000],

    ticksRange: ["0", "5K", "10K", "25K", "50K", "100K+"],

    categories: [],
    sortingItems: [
      {
        text: "Recommended",
        orderBy: "recommended",
        asc: true
      },
      {
        text: "Alphabetical",
        orderBy: "alphabetical",
        asc: true
      },
      {
        text: "Alphabetical",
        orderBy: "alphabetical",
        desc: true
      },
      {
        text: "Newest",
        orderBy: "newest",
        desc: true
      },
      {
        text: "Oldest",
        orderBy: "newest",
        asc: true
      },
      {
        text: "Followers high-low",
        orderBy: "followers",
        asc: false,
        permission: 'sort_by_impressions'
      },
      {
        text: "Impressions high-low",
        orderBy: "impressions",
        asc: false,
        permission: 'sort_by_impressions'
      }
    ],
    genderItems: [
      {
        text: "Male",
        id: "male"
      },
      {
        text: "Female",
        id: "female"
      }
    ],
    prevRouteName: null,
    isUserScrolling: false,
    handleDebouncedScroll: null
  }),
  computed: {
    ...mapGetters("core/auth", ["isAgency"]),
    ...mapState("core/auth", ["user"]),
    ...mapGetters("subscriptions", ["hasPermission"]),
    ...mapState("filters", ["selectedCountries"]),
    formattedCategories() {
      return this.categories.map(item => ({
        text: item.display_name,
        value: Number(item.id)
      }));
    },
    isFilterChips() {
      const filters = this.cloneDeep(this.filters);
      return (
        !!Object.keys(filters).length &&
        ((filters.category_ids && filters.category_ids.length) ||
          (filters.gender && Object.keys(filters.gender).length) ||
          (filters.age_ranges && filters.age_ranges.length) ||
          !!filters.max_followers ||
          !!filters.min_followers ||
          (filters.age_genders && filters.age_genders.length) ||
          (filters.cities && filters.cities.length))
      );
    }
  },
  watch: {
     search: {
      handler: _.debounce(function() {
        this.saveFilters();
      }, 500)
    },
    selectedSorting: {
      handler: _.debounce(function() {
        if (!this.selectedSorting) {
          delete this.filters.orderBy;
          delete this.filters.order;
          return null;
        }
        this.filters.orderBy = this.selectedSorting.orderBy;
        this.filters.order = this.selectedSorting.asc ? "asc" : "desc";
        if (!this.selectedSorting.asc && !this.selectedSorting.desc) {
          delete this.filters.orderBy;
          delete this.filters.order;
        }
        this.saveFilters();
      }, 500)
    },
    selectedCountries: {
      handler: _.debounce(function() {
        this.filters.country_codes = this.selectedCountries;
        this.saveFilters();
      }, 500)
    }
  },

  methods: {
    ...mapActions("core", ["getCountries"]),
    ...mapActions("core", ["getInfluencers", "getChannelCategories"]),
    ...mapActions("filters", ["setSelectedCountries"]),
    loadActiveCountries() {
      this.getCountries({ active: true }).then(
        countries => {
          this.countries = countries;
          this.updateSelectedCountries();
        },
        error => {
          console.log(error);
        }
      );
    },
    updateSelectedCountries() {
      this.setSelectedCountries(this.mySelectedCountries);
    },
    handleScroll() {
      this.isUserScrolling = window.scrollY > 200;
    },
    toggleFavourite(value) {
      this.filters.favorite = value;
      this.saveFilters();
    },
    searchMethod: _.debounce(function () {
        this.saveFilters();
        }, 500),
    saveSearch() {
      this.filters.search = this.search;
      this.saveFilters();
      this.search = null;
      this.showMobileSearch = false;
    },
    handlePageChange(value) {
      this.scrollToTop();
      this.page = value;
      this.loadInfluencers(value);
    },
    saveAdvancedFilters(event) {
      this.filters = event;
      this.page = 1;
      this.loadInfluencers(this.page);
    },
    saveFilters() {
      this.page = 1;
      this.loadInfluencers(this.page);
    },
    influencerChange(event) {
      let item = this.influencers.find(item => item.uuid === event.uuid);
      item.is_favorite = event.value;
      this.$refs[item.uuid][0].updateFavorite(event.value);
    },
    showStatsModal(influencer) {
      this.selectedInfluencer = influencer;
      this.showInfluencerDialog = true;
    },
    addSkeletons() {
      let arr = [];
      for (let i = 0; i < 12; i++) {
        arr.push({ uuid: "skeleton" });
      }
      this.skeletonArray = arr;
    },
    removeSkeletons() {
      this.skeletonArray = [];
    },
    removeChip(event) {
      let idx = null;
      if (event.item === "gender") {
        delete this.filters["gender"];
      } else if (event.item === "followers") {
        delete this.filters["min_followers"];
        delete this.filters["max_followers"];
      } else {
        idx = this.filters[event.item].findIndex(
          filter => filter.id === event.id
        );
        this.filters[event.item].splice(idx, 1);
      }
      this.cleanFilters(true);
    },
    loadInfluencers(page = 1) {
      // Prevent double loading
      if (this.loading) {
        return null;
      }
      // add skeletons
      this.influencers = [];
      this.addSkeletons();
      this.loading = true;
      // set params
      this.filters.page = page;
      this.filters.search = this.search;
      this.filters.orderBy = this.selectedSorting.orderBy;
      this.filters.order = this.selectedSorting.asc ? "asc" : "desc";
      let filters = this.cloneDeep(this.filters);
      filters.limit = this.limit;
      filters.status = [1, 3]; // only active and system hidden influencers
      filters.page = page;
      const filteredAdvancedKeys = ["cities", "age_genders"];
      if (filters["age_ranges"]) {
        const arr = [];
        filters["age_ranges"].map(id => {
          const item = this.ageItems.find(age => {
            return age.value === id;
          });
          if (item) {
            arr.push({ min: item.min, max: item.max });
          }
        });
        filters["age_ranges"] = arr;
      }
      if (filters["category_ids"]) {
        filters["category_ids"] = filters["category_ids"].map(item =>
          Number(item)
        );
      }
      if (filters["gender"]) {
        filters["gender"] = filters.gender.id;
      }
      if (filters["max_followers"] === 100000) {
        delete filters["max_followers"];
      }
      filteredAdvancedKeys.forEach(key => {
        if (filters[key])
          filters[key] = filters[key].map(({ followers, id }) => ({
            followers,
            id
          }));
      });
      // save to url
      this.setUrlSearchFilters(this.filters);
      // Request
      this.getInfluencers(filters).then(
        influencers => {
          // set items
          if (influencers.response.length > 0) {
            this.totalPages = influencers.lastPage;
            this.totalInfluencers = influencers.total;
            this.influencers = influencers.response;
            this.checkFavorite(this.influencers);
          } else {
            this.removeSkeletons();
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.removeSkeletons();
        }
      );
    },
    checkFavorite(list) {
      list.map(item => {
        const favoriteArray = item.favorite_by_business.map(el => el.uuid);
        if (!this.isAgency) {
          if (favoriteArray.includes(this.user.business.uuid)) {
            item.is_favorite = true;
          } else {
            item.is_favorite = false;
          }
        }
        return item;
      });
      this.$forceUpdate();
    },
    setRange(isOpen) {
      if (!isOpen && this.range.length) {
        const min = this.range[0];
        const max = this.range[1];
        const filters = this.cloneDeep(this.filters);
        if (max === 5) {
          filters.min_followers = this.ticksNumberRange[min];
          filters.max_followers = this.ticksNumberRange[max];
        } else {
          filters.min_followers = this.ticksNumberRange[min];
          filters.max_followers = this.ticksNumberRange[max];
        }
        this.filters = { ...this.filters, ...filters };
        this.saveFilters();
      }
    },
    selectSorting(item) {
      if(! item.permission || this.hasPermission(item.permission)) {
        this.selectedSorting = item;
      }
    },
    cleanFilters(removeAll) {
      const filters = this.cloneDeep(this.filters);

      const filteredKeys = [
        "age_ranges",
        "gender",
        "max_followers",
        "min_followers",
        "category_ids",
        "age_genders",
        "cities",
        "favorite"
      ];
      if (removeAll) {
        filteredKeys.forEach(key => {
          if (removeAll && filters[key] && filters[key].length === 0)
            delete filters[key];
        });
      } else {
        filteredKeys.forEach(key => {
          if (filters[key]) delete filters[key];
        });
      }
      this.filters = filters;

      this.saveFilters();
    }
  },
  created() {
    this.handleDebouncedScroll = _.debounce(this.handleScroll, 200);
    window.addEventListener("scroll", this.handleDebouncedScroll);
    if (Object.keys(this.$route.query).length > 0) {
      this.filters = this.cloneDeep(this.getUrlSearchFilters());
      this.page = this.filters.page;
      this.search = this.filters.search ? this.filters.search : null;
      const sort = {
        orderBy: this.filters.orderBy,
        [this.filters.order]: true
      };
      if (!sort.orderBy) {
        this.cleanFilters(true);
      }
      const sorting = this.sortingItems.filter(
        item =>
          item.orderBy === sort.orderBy &&
          Object.keys(item)[2] === this.filters.order
      );
      this.selectedSorting = sorting[0];
      if (this.filters.min_followers) {
        this.range = [
          this.ticksNumberRange.indexOf(this.filters.min_followers),
          this.ticksNumberRange.indexOf(this.filters.max_followers)
        ];
        if (!this.filters.max_followers) {
          this.range[1] = 5;
        }
      } else {
        this.range = [0, 5];
      }
    }
    this.loadActiveCountries();
    this.loadInfluencers(this.page);
    this.getChannelCategories().then(response => {
      this.categories = response.data.channelCategories;
    });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRouteName = from.name;
    });
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener("scroll", this.handleDebouncedScroll);
  }
};
</script>
<style lang="scss" scoped>
.influencers-list {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
}
.influencer-skeleton {
  justify-content: center;
  padding: 10px;
  .rounded {
    border-radius: 999px !important;
  }
  .influencer-skeleton-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}

.custom-select {
  margin-right: 30px;
  width: 120px;
  flex: initial;
  &.width {
    width: 80px;
  }
  .v-select__selections {
    width: 90px;
  }
}

.sort-icon {
  font-size: 9px;
  color: #828282;
}
.total-wrap {
  font-size: 11px;
  color: #bdbdbd;
}
.first-btn {
  bottom: 70px;
}
.theme--light.v-list-item:not(.v-list-item--disabled) {
  color: var(--v-primary-base) !important;
}
.empty-search-img {
  text-align: center;
  margin-top: 50px;
  img {
    width: 300px;
  }
}
</style>
